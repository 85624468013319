/* TokenInfo.css */
.token-info-box {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
  max-width: 600px;
}

.token-info-box h3 {
  margin-top: 0;
  color: #333;
}

.token-info-box pre {
  background-color: #eee;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
  font-size: 14px;
  white-space: pre-wrap;
}

.token-info-box h4 {
  margin-bottom: 5px;
  color: #555;
}
