/* Greetings.css */
.greetings-box {
  background-color: #f0f8ff;
  border: 2px solid #0077ff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.greetings-box h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.greetings-box h2 span {
  color: #0077ff;
}