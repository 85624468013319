/* Logout.css */
.logout-button {
  background-color: #ff4d4d; /* Red color for logout */
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.logout-button:hover {
  background-color: #cc0000; /* Darker red on hover */
}