.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Style the navigation bar container */
nav {
  background-color: #333; /* Dark background for contrast */
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

/* Unordered list styling (flexbox for horizontal alignment) */
nav ul {
  display: flex;
  justify-content: center; /* Center the items horizontally */
  margin: 0;
  padding: 0;
  list-style: none; /* Remove bullets */
}

/* Each list item (spacing between the links) */
nav ul li {
  margin: 0 20px; /* Spacing between links */
}

/* Link styling */
nav ul li a {
  color: white; /* White text for contrast against dark background */
  text-decoration: none; /* Remove underline */
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px; /* Add some padding for clickable area */
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  border-radius: 4px;
}

/* Hover effect for links */
nav ul li a:hover {
  background-color: #555; /* Darker shade on hover */
  color: #f1f1f1;
}

/* Active or current page link styling */
nav ul li a.active {
  background-color: #0077ff; /* Different color to highlight active page */
  color: white;
  font-weight: 600;
}

/* Optional: Add responsiveness for small screens */
@media (max-width: 768px) {
  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 10px 0;
  }
}