/* Login.css */

/* General styles for centering the form */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f7f7;
  font-family: 'Arial', sans-serif;
}

.login-form-wrapper {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.login-title {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-size: 24px;
  font-weight: 600;
}

/* Form styling */
.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
  color: #444;
}

.login-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out;
}

.login-input:focus {
  border-color: #0077ff;
  outline: none;
}

/* Button styling */
.login-button {
  background-color: #0077ff;
  color: white;
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.login-button:hover {
  background-color: #005bb5;
}

.login-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* Error message styling */
.login-error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
